import { ExperimentItem, Variants_Enum } from './types'

export const experiments: readonly ExperimentItem[] = [
  {
    id: 'sku_pricing_20250403',
    description: 'Aumento do preço do kit de 20 marmitas pt3',
    setInitialValueInSSR: true,
    setInitialValueInHook: false,
    variants: [
      {
        id: 'control',
        value: Variants_Enum.A,
        weight: 80,
      },
      {
        id: 'variant',
        value: Variants_Enum.B,
        weight: 20,
      },
    ],
  },
]
